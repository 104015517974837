var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "outer-link text"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _setup.open('https://www.njzq.com.cn/njzq/index.html');
      }
    }
  }, [_vm._v("南京证券官网")]), _c('span', {
    on: {
      "click": function ($event) {
        return _setup.open('https://www.amac.org.cn/');
      }
    }
  }, [_vm._v("中国基金业协会官网")])]), _c('div', {
    staticClass: "split-line"
  }), _c('div', {
    staticClass: "text"
  }, [_c('span', [_vm._v("南京巨石创业投资有限公司版权所有")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function ($event) {
        return _setup.open('https://beian.miit.gov.cn/');
      }
    }
  }, [_vm._v("备案许可证编号：苏ICP备12038221号")]), _c('span', {
    staticClass: "link",
    on: {
      "click": function ($event) {
        return _setup.open('https://beian.mps.gov.cn/#/query/webSearch?code=32010202011700');
      }
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "src": require("@/assets/imgs/ga.png")
    }
  }), _vm._v("苏公网安备32010202011700号")]), _vm._m(0)]), _vm._m(1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', [_vm._v("本网站已支持 "), _c('img', {
    attrs: {
      "src": require("@/assets/imgs/ipv6.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "text"
  }, [_c('span', [_vm._v("公司注册地址：南京市玄武区大钟亭8号南京证券大厦4楼")]), _c('span', [_vm._v("邮编：210008")])]);
}];
export { render, staticRenderFns };