import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/components/Layout.vue'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

/**
 * name -> 同path，多级路由时用 . 分割
 * meta.title -> 面包屑中的菜单名称
 * meta.smooth -> 设为false在切换页面时不会平滑地滚动到顶部，否则会平滑地滚动到顶部
 */
const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: Layout,
    meta: { title: '巨石创投官网' },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/* webpackChunkName: "page-home" */ '@/views/home/index.vue'),
        meta: { title: '首页', smooth: false }
      },
      {
        path: 'eventPage',
        name: 'eventPage',
        component: () => import(/* webpackChunkName: "page-event" */ '@/views/home/eventPage.vue'),
        meta: { title: '公司动态', smooth: false },
      },
      {
        path: 'eventDetail/:docId',
        name: 'eventDetail',
        component: () => import(/* webpackChunkName: "page-event" */ '@/views/home/eventDetail.vue'),
        meta: { title: '动态详情', smooth: false },
      },
      {
        path: 'about',
        name: 'about',
        redirect: '/about/profile',
        component: () => import(/* webpackChunkName: "page-about" */ '@/views/about/index.vue'),
        meta: { title: '关于我们' },
        children: [
          {
            path: 'profile',
            name: 'about.profile',
            component: () => import(/* webpackChunkName: "page-about" */ '@/views/about/profile.vue'),
            meta: { title: '公司简介' },
          },
          {
            path: 'history',
            name: 'about.history',
            component: () => import(/* webpackChunkName: "page-about" */ '@/views/about/history.vue'),
            meta: { title: '历史沿革' },
          },
          {
            path: 'philosophy',
            name: 'about.philosophy',
            component: () => import(/* webpackChunkName: "page-about" */ '@/views/about/philosophy.vue'),
            meta: { title: '经营理念' },
          },
          {
            path: 'organization',
            name: 'about.organization',
            component: () => import(/* webpackChunkName: "page-about" */ '@/views/about/organization.vue'),
            meta: { title: '组织架构' },
          },
          {
            path: 'achivement',
            name: 'about.achivement',
            component: () => import(/* webpackChunkName: "page-about" */ '@/views/about/achivement.vue'),
            meta: { title: '获得荣誉' },
          },
        ]
      },
      {
        path: 'investment',
        name: 'investment',
        redirect: '/investment/funds',
        component: () => import(/* webpackChunkName: "page-investment" */ '@/views/investment/index.vue'),
        meta: { title: '投资实力' },
        children: [
          {
            path: 'funds',
            name: 'investment.funds',
            component: () => import(/* webpackChunkName: "page-investment" */ '@/views/investment/funds.vue'),
            meta: { title: '在管基金' },
          },
          {
            path: 'projects',
            name: 'investment.projects',
            component: () => import(/* webpackChunkName: "page-investment" */ '@/views/investment/projects.vue'),
            meta: { title: '已投项目代表' },
          },
          {
            path: 'philosophy',
            name: 'investment.philosophy',
            component: () => import(/* webpackChunkName: "page-investment" */ '@/views/investment/philosophy.vue'),
            meta: { title: '投资理念' },
          },
        ]
      },
      {
        path: 'information',
        name: 'information',
        redirect: '/information/rules',
        component: () => import(/* webpackChunkName: "page-information" */ '@/views/information/index.vue'),
        meta: { title: '信息公示' },
        children: [
          {
            path: 'rules',
            name: 'information.rules',
            component: () => import(/* webpackChunkName: "page-information" */ '@/views/information/rules.vue'),
            meta: { title: '相关制度' },
          },
          {
            path: 'team',
            name: 'information.team',
            component: () => import(/* webpackChunkName: "page-information" */ '@/views/information/team.vue'),
            meta: { title: '管理团队' },
          },
          {
            path: 'practitioners',
            name: 'information.practitioners',
            component: () => import(/* webpackChunkName: "page-information" */ '@/views/information/practitioners.vue'),
            meta: { title: '从业人员' },
          },
          {
            path: 'businessIntro',
            name: 'information.businessIntro',
            component: () => import(/* webpackChunkName: "page-information" */ '@/views/information/businessIntro.vue'),
            meta: { title: '业务介绍' },
          },
          {
            path: 'announcement',
            name: 'information.announcement',
            component: () => import(/* webpackChunkName: "page-information" */ '@/views/information/announcement.vue'),
            meta: { title: '重要声明' },
          },
        ]
      },
      {
        path: 'education',
        name: 'education',
        redirect: '/education/educationQA',
        component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/index.vue'),
        meta: { title: '投资者教育' },
        children: [
          {
            path: 'educationQA',
            name: 'education.educationQA',
            component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/educationQA.vue'),
            meta: { title: 'Q&A' },
          },
          {
            path: 'educationVideo',
            name: 'education.educationVideo',
            component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/educationVideo.vue'),
            meta: { title: '投教视频' },
          },
          {
            path: 'educationCase',
            name: 'education.educationCase',
            component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/educationCase.vue'),
            meta: { title: '投教案例' },
          },
          {
            path: 'educationCaseDetail',
            name: 'education.educationCase.educationCaseDetail',
            component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/educationCaseDetail.vue'),
            meta: { title: '投教案例', back: '/education/educationCase' },
          },
          
          {
            path: 'educationTips',
            name: 'education.educationTips',
            component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/educationTips.vue'),
            meta: { title: '宣传小贴士' },
          },
          {
            path: 'educationTipsDetail1',
            name: 'education.educationTips.educationTipsDetail1',
            component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/educationTipsDetail1.vue'),
            meta: { title: '反洗钱知识问答', back: '/education/educationTips' },
          },
          {
            path: 'educationTipsDetail2',
            name: 'education.educationTips.educationTipsDetail2',
            component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/educationTipsDetail2.vue'),
            meta: { title: '一图读懂北交所规则', back: '/education/educationTips' },
          },
          {
            path: 'educationTipsDetail3',
            name: 'education.educationTips.educationTipsDetail3',
            component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/educationTipsDetail3.vue'),
            meta: { title: '私募基金特点', back: '/education/educationTips' },
          },
          {
            path: 'educationTipsDetail4',
            name: 'education.educationTips.educationTipsDetail4',
            component: () => import(/* webpackChunkName: "page-education" */ '@/views/education/educationTipsDetail4.vue'),
            meta: { title: '防范非法集资', back: '/education/educationTips' },
          },
        ]
      },
      {
        path: 'contact',
        name: 'contact',
        redirect: '/contact/recommond',
        component: () => import(/* webpackChunkName: "page-contact" */ '@/views/contact/index.vue'),
        meta: { title: '联系我们' },
        children: [
          {
            path: 'recommond',
            name: 'contact.recommond',
            component: () => import(/* webpackChunkName: "page-contact" */ '@/views/contact/recommond.vue'),
            meta: { title: '项目推荐' },
          },
          {
            path: 'hire',
            name: 'contact.hire',
            component: () => import(/* webpackChunkName: "page-contact" */ '@/views/contact/hire.vue'),
            meta: { title: '招聘信息' },
          },
          {
            path: 'contactInfo',
            name: 'contact.contactInfo',
            component: () => import(/* webpackChunkName: "page-contact" */ '@/views/contact/contactInfo.vue'),
            meta: { title: '联系信息' },
          },
        ]
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "page-404" */ '@/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.VUE_APP_PUBLIC_PATH,
  scrollBehavior (to, from, savedPosition) {
    if (to.meta.smooth === false) {
      return { x: 0, y: 0 }
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  },
  routes,
})

export { router }
