/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./App.vue?vue&type=template&id=cf5d3d00&ref=main"
import script from "./App.vue?vue&type=script&setup=true&lang=js"
export * from "./App.vue?vue&type=script&setup=true&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=cf5d3d00&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports