import 'core-js/stable';
import Vue from 'vue';
import App from './App.vue';
import './style/main.scss';
import { router } from './router';
// import { Loading } from 'element-ui';   // Loading不支持ie ？
// Vue.use(Loading.directive)
import Loading from './directives/loading/loading';
import sensors from 'sa-sdk-javascript';
Vue.config.productionTip = false;
Vue.directive('loading', Loading);

// 神策
sensors.init({
  server_url: 'https://mdfx.njzq.cn:8106/sa?project=production',
  heatmap: {
    scroll_notice_map: 'not_collect'
  },
  is_track_single_page: true,
  // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: 'beacon'
});
sensors.quick('autoTrack'); //用于采集 $pageview 事件。
Vue.prototype.$sensors = sensors;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');