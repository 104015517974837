export default {
  __name: 'PageFooter',
  setup(__props) {
    const open = url => {
      window.open(url);
    };
    return {
      __sfc: true,
      open
    };
  }
};