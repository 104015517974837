import { ref, onMounted } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const main = ref();
    const isMobile = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    onMounted(() => {
      if (isMobile()) {
        console.log('isMobile');
        const scale = document.documentElement.clientWidth / 960;
        main.value.style = `transform: scale(${scale});transform-origin: top left;`;
      }
    });
    return {
      __sfc: true,
      main,
      isMobile
    };
  }
};