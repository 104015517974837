var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    ref: "main",
    staticClass: "main"
  }, [_c('RouterView')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };