import loadingUrl from './loading.svg'
import style from './loading.module.css'

/**以下为辅助函数**/
/**
* 得到loading效果的img元素
* @param el 传入的dom元素
*/
function getLoadingImg(el) {
  //查找图片中有data-role为loading的图片
  return el.querySelector('div[data-role=loading]');
}

/**
* 创建一个加载loading图片的元素
*/
function createLoadingImg() {
  const div = document.createElement('div')
  div.className = style.loadingmask
  //添加data-role为loading的属性
  div.dataset.role = 'loading'
  const img = document.createElement('img');
  //给创建的img元素添加图片地址
  img.src = loadingUrl;
  //给img添加className
  img.className=style.loading
  div.appendChild(img)
  return div;
}

function isIE() {
	if (!!window.ActiveXobject || "ActiveXObject" in window) {
		return true;
	} else {
		return false;
	}
}
function isIE11(){
	if((/Trident\/7\./).test(navigator.userAgent)) {
		return true;
	} else {
		return false;
	}
}


export default function (el, binding) {
  //获取当前的有loading效果的img元素
  const curMask=getLoadingImg(el);
  //根据binding.value的值，决定创建或者删除img元素
  if (binding.value) {
    // 判断loading效果的img元素是否存在
    if (!curMask) {
      //创建该元素
      const loadingMask = createLoadingImg();
      //把创建好的元素加入到dom元素中
      el.style.position = 'relative'
      el.appendChild(loadingMask)
    }
  } else {
    //如果binding.value值为false或者不存在
    //判断是否有loadingImg元素
    if(curMask){
      //移除loadingImg元素
      el.style.position = 'static'
      if (isIE() || isIE11()) {
        curMask.removeNode(true)
      } else {
        curMask.remove()
      }
    }
  }
}