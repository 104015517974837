var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('PageHeader'), _c('div', {
    staticClass: "body"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('RouterView')], 1)], 1), _c('PageFooter')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };